import { useMemo } from 'react';

// Context
import useHeader from '../hooks/use-header';
import { useBasket } from '/controllers/basket';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

export const MobileSubnav = () => {
	const { toggleMobileMenu, toggleUserMenu } = useHeader();
	const { userData } = useAuth();
	const { summary: basketSummary } = useBasket();
	const { activeRoute } = useNavigator();

	const { isBlog, isAccount } = useMemo(() => {
		return {
			isBlog: activeRoute?.key.startsWith('blog'),
			isAccount: activeRoute?.key.startsWith('account'),
		}
	}, [activeRoute])

	return (
		<nav className={`section header-mobile-subnav${isBlog ? ' blog' : ''}`}>
			<ul className="subnav-list">
				<li className="list-item">
					<Link navLink className="item-link" href="home">
						<Icon className="item-icon" name="menu-home" />
						<span className="item-text">Ana Sayfa</span>
					</Link>
				</li>
				{!isBlog ?
					<>
						<li className="list-item">
							<button
								className="item-link"
								type="button"
								onClick={() => { toggleMobileMenu() }}>
								<Icon className="item-icon" name="menu-categories" />
								<span className="item-text">Kategoriler</span>
							</button>
						</li>
						<li className="list-item">
							<Link navLink className="item-link" href="basket">
								<Icon className="item-icon" name="menu-cart" />
								<span className="item-count">{basketSummary.basket_quantity > 9 ? '9+' : basketSummary.basket_quantity}</span>
								<span className="item-text">Sepet</span>
							</Link>
						</li>
						<li className="list-item">
							<Link navLink className="item-link" href="campaigns">
								<Icon className="item-icon" name="menu-campaigns" />
								<span className="item-text">Kampanyalar</span>
							</Link>
						</li>
						<li className="list-item">
							{userData ?
								<button
									className={`item-link${isAccount ? ' active' : ''}`}
									type="button"
									onClick={toggleUserMenu}>
									<Icon className="item-icon" name="account" />
									<span className="item-text">Hesabım</span>
								</button>
								:
								<Link
									href="login"
									className="item-link">
									<Icon className="item-icon" name="account" />
									<span className="item-text">Hesabım</span>
								</Link>
							}
						</li>
					</>
					:
					<>
						<li className="list-item">
							<Link
								className="item-link"
								href="blog.tools">
								<Icon className="item-icon" name="menu-tools" />
								<span className="item-text">Araçlar</span>
							</Link>
						</li>
						<li className="list-item">
							<Link
								className="item-link"
								href="blog.subjects">
								<Icon className="item-icon" name="menu-subjects" />
								<span className="item-text">Konular</span>
							</Link>
						</li>
					</>
				}
			</ul>
			{/* {isApplication &&
				<div className="subnav-appcontrols">
					<span className="appcontrols-text">
						<i className="cart" /> Alışveriş
					</span>
					<Link
						type="button"
						href={isBlog ? 'root.home' : 'blog.tools'}
						className={`appcontrols-switcher${isBlog ? ' switched' : ''}`}>
						<span></span>
					</Link>
					<span className="appcontrols-text">
						<i className="heart-full" /> Bebeğim İçin
					</span>
				</div>
			} */}
		</nav>
	)
}

export default MobileSubnav;